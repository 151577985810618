<template>
    <div v-if="authUserPermission['kpi-mcr-creation-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6 header-body">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col-2">
                            <h3 class="mb-0">{{ tt('mcr_process') }}</h3>
                        </div>
                        <div class="col-5">
                            <!-- <input type="text" class="form-control form-control-sm border-radius-20 text-center" v-model="search" :placeholder="tt('search')" v-on:keyup="filter"/> -->
                        </div>
                        <div class="col-5 text-right">
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link btn btn-dark btn-sm">
                                    {{tt('year')}}<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="scroll">
                                    <div class="container">
                                        <template v-for="(year, i) in years">
                                            <input type="checkbox" :value="year" v-model="search_years" @change="filter()"> {{ year}} <br>
                                        </template>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link btn btn-dark btn-sm">
                                     {{tt('month')}}<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="scroll">
                                    <div class="container">
                                        <template v-for="(month, i) in month">
                                            <input type="checkbox" :value="(i+1 < 10) ?  '0'+(i+1) : i+1" v-model="search_month" @change="filter()"> {{ month }} <br>
                                        </template>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>

                            <a v-if="search_years == 0 && search_month == 0 || search_years != 0 && search_month != 0" :href="apiUrl+'McrProcess/export?'+paramsExport+'&token='+token" class="btn btn-sm btn-outline-dark">{{ tt('export_as_excel') }}</a>
                            <a v-if="search_month == 0 && search_years != 0" :href="apiUrl+'McrProcess/export?'+paramsExport+'search_month='+'&token='+token" class="btn btn-sm btn-outline-dark">{{ tt('export_as_excel') }}</a>
                            <a v-if="search_years == 0 && search_month != 0" :href="apiUrl+'McrProcess/export?search_years=&'+paramsExport+'&token='+token" class="btn btn-sm btn-outline-dark">{{ tt('export_as_excel') }}</a>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" style="height: 500px">
                    <table class="table" v-if="!onLoad.table">
                        <thead>
                            <tr>
                                <th class="text-center" colspan="9">{{ tt('year')+ ' : ' +search_years }} {{ tt('month')+ ' : ' +search_month }}</th>
                            </tr>
                            <tr>
                                <th class="text-center">{{ tt('company_code') }}</th>
                                <th class="text-center">{{ tt('no_mcr') }}</th>
                                <th class="text-center">{{ tt('type_mcr') }}</th>
                                <th class="text-center">{{ tt('requested_at') }}</th>
                                <th class="text-center">{{ tt('approved_at') }}</th>
                                <th class="text-center">{{ tt('total_duration') }}</th>
                                <th class="text-center">{{ tt('no_item') }}</th>
                                <th class="text-center">{{ tt('note') }}</th>
                                <th class="text-center">{{ tt('status') }}</th>
                                <th class="text-center">{{ tt('cataloged_by') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in table.data">
                                <td class="text-center">{{item.company_code}}</td>
                                <td class="text-center">{{item.mcr_number}}</td>
                                <td class="text-center">{{item.form_description}}</td>
                                <td class="text-center">{{moment(item.created_at).locale('id').format('LL')}}</td>
                                <td class="text-center">{{moment(item.updated_at).locale('id').format('LL')}}</td>
                                <td class="text-center">{{ count_process(item.created_at, item.updated_at) }}</td>
                                <td class="text-center">{{item.mcr_item_code}}</td>
                                <td class="text-center">{{item.note}}</td>
                                <td class="text-center">{{item.status}}</td>
                                <td class="text-center">{{item.processed_by}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <skeleton-loading v-else/>
                </div>
                <div class="card-footer pb-0 ">   
                    <span class="float-left">
                        <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                    </span>                             
                    <span class="float-right">
                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <noaccess v-else/>
</template>
<script>
	import {mapState} from "vuex";
	import Api from '@/helpers/api';
	import mcrProcess from '@/services/report/mcrProcess.service';
	import config from '@/configs/config';
    var moment = require('moment');

	export default {
		data() {
			return {
                moment:moment,
				date: '',
				table: {                
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
				},
				onLoad: {
					table: true
				},
                years: [],
                month: ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'],
                search_years: [],
                search_month: [],
                paramsExport:[],
                toArray: [],

				apiUrl: config.apiUrl,
                search : '',
				token: localStorage.getItem('token')
			}
		},
		computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
            uniqueAyam: function () {
              var existingIds = {};
              return this.table.data.filter(function (user) {
                if (existingIds[user.id_cms_users]) return false;
                return existingIds[user.id_cms_users] = true;
              })
            }
		},
		mounted() {
			this.get();
            this.genereteYears();
		},
		methods: {
			get() {
				let context = this;
				Api(context, mcrProcess.index(this.table.page.current_page, {search_years: this.search_years, search_month: this.search_month})).onSuccess(function(response) {
                    context.table.total  = response.data.data.data.total;
                    context.table.data   = response.data.data.data.data;
                    context.table.page   = response.data.data.data;
                    context.onLoad.table = false;
				}).onError(function(error) {
                    if (error.response.status == 404) {
                        context.table.data   = [];
                        context.table.total  = 0;
                        context.onLoad.table = false;
                    }
				}).call()

                this.getParamsExport()
			},
            filter() {
                this.onLoad.table = false;
                this.table.page.current_page = 1;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get();
                }, 100);
            },
            getParamsExport(){
                this.toArray = [];

                if (this.search_years.length != 0 || this.search_month.length != 0) {
                    for (var i = 0; i < this.search_years.length; i++) {
                        var search_years = 'search_years[]='+this.search_years[i]+'&'
                        this.toArray.push(search_years);
                    }

                    for (var i = 0; i < this.search_month.length; i++) {
                        var search_month = 'search_month[]='+this.search_month[i]+'&'
                        this.toArray.push(search_month);
                    }

                    this.string       = this.toArray.toString()
                    this.removeKoma   = this.string.replace(/,/g, "");
                    this.paramsExport = this.removeKoma;
                }else{
                    this.paramsExport = 'search_years=&search_month='
                }

                console.log(this.paramsExport);
            },
            count_process(create, update){
                if (create) {
                    var a = moment(create);
                    var b = moment(update);
                    return b.diff(a, 'days') + ' days'
                }
            },
            genereteYears(){
                var min = 2021;
                var max = new Date().getFullYear()

                for (var i = max; i >= min; i--) {
                    this.years.push(i)
                }
            },
            changePage(page) {
                let context = this;
                context.onLoad.table = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            }
		}
	};
</script>

<style>
    .scroll{
        height: 200px; 
        overflow-y: auto; 
        overflow-x: hidden;
    }
</style>
